define("discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/helpers/d-icon", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/modifiers/chat/later-fn", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _dIcon, _ajaxError, _discourseI18n, _laterFn, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MentionWithoutMembership extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [(0, _service.service)("chat-api")]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "invitationsSent", [_tracking.tracked], function () {
      return false;
    }))();
    #invitationsSent = (() => (dt7948.i(this, "invitationsSent"), void 0))();
    get userIds() {
      return this.args.notice.data.user_ids;
    }
    async sendInvitations(event) {
      // preventDefault to avoid a refresh
      event.preventDefault();
      try {
        await this.chatApi.invite(this.args.channel.id, this.userIds, {
          messageId: this.args.notice.data.messageId
        });
        this.invitationsSent = true;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "sendInvitations", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="mention-without-membership-notice">
          {{#if this.invitationsSent}}
            <span
              class="mention-without-membership-notice__invitation-sent"
              {{laterFn @clearNotice 3000}}
            >
              {{icon "check"}}
              <span>
                {{i18n
                  "chat.mention_warning.invitations_sent"
                  count=this.userIds.length
                }}
              </span>
            </span>
          {{else}}
            <p class="mention-without-membership-notice__body -without-membership">
              <span
                class="mention-without-membership-notice__body__text"
              >{{@notice.data.text}}</span>
              <a
                class="mention-without-membership-notice__body__link"
                href
                {{on "click" this.sendInvitations}}
              >
                {{i18n "chat.mention_warning.invite"}}
              </a>
            </p>
          {{/if}}
        </div>
      
    */
    {
      "id": "0ZGGlYuP",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"mention-without-membership-notice\"],[12],[1,\"\\n\"],[41,[30,0,[\"invitationsSent\"]],[[[1,\"        \"],[11,1],[24,0,\"mention-without-membership-notice__invitation-sent\"],[4,[32,0],[[30,1],3000],null],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"check\"],null]],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"chat.mention_warning.invitations_sent\"],[[\"count\"],[[30,0,[\"userIds\",\"length\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[14,0,\"mention-without-membership-notice__body -without-membership\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"mention-without-membership-notice__body__text\"],[12],[1,[30,2,[\"data\",\"text\"]]],[13],[1,\"\\n          \"],[11,3],[24,0,\"mention-without-membership-notice__body__link\"],[24,6,\"\"],[4,[32,3],[\"click\",[30,0,[\"sendInvitations\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"chat.mention_warning.invite\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@clearNotice\",\"@notice\"],false,[\"if\"]]",
      "moduleName": "/home/pacs/vcg01/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership.js",
      "scope": () => [_laterFn.default, _dIcon.default, _discourseI18n.i18n, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MentionWithoutMembership;
});