define("discourse/plugins/chat/discourse/components/chat-message/blocks/index", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/plugins/chat/discourse/components/chat-message/blocks/block", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _ajaxError, _block, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Blocks extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    async createInteraction(id) {
      try {
        const result = await this.chatApi.createInteraction(this.args.message.channel.id, this.args.message.id, {
          action_id: id
        });
        this.appEvents.trigger("chat:message_interaction", result.interaction);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "createInteraction", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @message.blocks}}
          <div class="chat-message__blocks-wrapper">
            <div class="chat-message__blocks">
              {{#each @message.blocks as |blockDefinition|}}
                <Block
                  @createInteraction={{this.createInteraction}}
                  @definition={{blockDefinition}}
                />
              {{/each}}
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "a7K28Qp6",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"blocks\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-message__blocks-wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message__blocks\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"blocks\"]]],null]],null],null,[[[1,\"            \"],[8,[32,0],null,[[\"@createInteraction\",\"@definition\"],[[30,0,[\"createInteraction\"]],[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@message\",\"blockDefinition\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/home/pacs/vcg01/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message/blocks/index.js",
      "scope": () => [_block.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Blocks;
});