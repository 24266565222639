define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-info-members", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-members", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _navbar, _channelInfoMembers, _channelInfoNav, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesMembers extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    get backButton() {
      return {
        route: "chat.channel",
        models: this.args.model?.channel?.routeModels,
        title: (0, _discourseI18n.i18n)("chat.return_to_channel")
      };
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-drawer-routes --channel-info-members">
          {{#if @model.channel}}
            <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
              <navbar.BackButton
                @title={{this.backButton.title}}
                @route={{this.backButton.route}}
                @routeModels={{this.backButton.models}}
              />
              <navbar.ChannelTitle @channel={{@model.channel}} />
              <navbar.Actions as |a|>
                <a.ToggleDrawerButton />
                <a.FullPageButton />
                <a.CloseDrawerButton />
              </navbar.Actions>
            </Navbar>
    
            {{#if this.chatStateManager.isDrawerExpanded}}
              <div class="chat-drawer-content">
                <ChannelInfoNav @channel={{@model.channel}} @tab="members" />
                <ChannelMembers @channel={{@model.channel}} />
              </div>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "pI5EdRlB",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-drawer-routes --channel-info-members\"],[12],[1,\"\\n\"],[41,[30,1,[\"channel\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backButton\",\"title\"]],[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"FullPageButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@channel\",\"@tab\"],[[30,1,[\"channel\"]],\"members\"]],null],[1,\"\\n            \"],[8,[32,2],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"navbar\",\"a\"],false,[\"if\"]]",
      "moduleName": "/home/pacs/vcg01/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-info-members.js",
      "scope": () => [_navbar.default, _channelInfoNav.default, _channelInfoMembers.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesMembers;
});