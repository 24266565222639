define("discourse/plugins/chat/discourse/components/chat/navbar/channel-title", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _channelTitle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarChannelTitle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    get shouldLinkToSettings() {
      return this.chatStateManager.isDrawerExpanded || this.chatStateManager.isFullPageActive;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @channel}}
          {{#if this.shouldLinkToSettings}}
            <LinkTo
              @route="chat.channel.info.settings"
              @models={{@channel.routeModels}}
              class="c-navbar__channel-title"
            >
              <ChannelTitle @channel={{@channel}} />
            </LinkTo>
          {{else}}
            <div class="c-navbar__channel-title">
              <ChannelTitle @channel={{@channel}} />
            </div>
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "x1piALGS",
      "block": "[[[1,\"\\n\"],[41,[30,1],[[[41,[30,0,[\"shouldLinkToSettings\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"c-navbar__channel-title\"]],[[\"@route\",\"@models\"],[\"chat.channel.info.settings\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"c-navbar__channel-title\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/home/pacs/vcg01/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/channel-title.js",
      "scope": () => [_routing.LinkTo, _channelTitle.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarChannelTitle;
});